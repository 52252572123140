import React, { useState } from 'react'
import { Transition } from '@headlessui/react'
import { useTimeout } from '../libs/hooks'

type MessageType = 'notice' | 'alert'

type Props = {
  type: MessageType
  message: string
}

// SEE: https://tailwindui.com/components/application-ui/overlays/notifications#component-a065edf8f08a35f06943b2e266ca9af1
export default function Flash({ type, message }: Props) {
  const icon = type == 'notice' ? 'fa-check-circle' : 'fa-exclamation-circle'
  const color = type == 'notice' ? 'text-green-600' : 'text-red-600'
  const timeout = type == 'notice' ? 3000 : 6000
  const [isOpen, setIsOpen] = useState(true)

  const handleClose = () => { setIsOpen(false) }
  useTimeout(timeout, handleClose)

  return (
    <FlashMessage
      icon={icon} color={color} message={message} isOpen={isOpen} onClose={handleClose}
    />
  )
}

type MessageProps = {
  icon: string
  color: string
  message: string
  isOpen: boolean
  onClose: () => void
}

function FlashMessage({ icon, color, message, isOpen, onClose }: MessageProps) {
  return (
    <Transition
      show={isOpen}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-center">
            <div className="w-0 flex-1 flex justify-between">
              <p className="w-0 flex-1 text-sm font-medium text-gray-900">
                {message}
              </p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500" onClick={onClose}>
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}
