import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "mobileMenu" ]

  initialize() {
    this.isMobileMenuOpen = false
  }

  toggle() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen
    this.reloadMenu()
  }

  reloadMenu() {
    if (this.isMobileMenuOpen) {
      this.mobileMenuTarget.classList.add("block")
      this.mobileMenuTarget.classList.remove("hidden")
    } else {
      this.mobileMenuTarget.classList.add("hidden")
      this.mobileMenuTarget.classList.remove("block")
    }
  }
}
