import React, {PureComponent} from 'react';
import PropTypes from "prop-types"
import {
  VictoryChart, VictoryTheme, VictoryGroup, VictoryArea, VictoryPolarAxis, VictoryLabel
} from 'victory';

type Props = {
  data: Array<Object>
}

function processData(data) {
  const makeDataArray = (d) => {
    return Object.keys(d).map((key) => {
      return { x: key, y: d[key] };
    });
  };
  return data.map((datum) => makeDataArray(datum));
}

export default function HermanChart({ data }: Props){
  const processedData = processData(data);
  return (
    <VictoryChart
      polar
      theme={VictoryTheme.material}
      domain={{ value: [0, 12]}}
      startAngle={45}
      endAngle={405}
    >
      <VictoryGroup colorScale={["purple"]} style={{ data: { fillOpacity: 0.2, strokeWidth: 2 }}}>
        {
          processedData.map((data, i) => {
            return <VictoryArea key={i} data={data} />;
          })
        }
      </VictoryGroup>
      {
        Object.entries(data[0]).map(([key, value], i) => {
          return (
            <VictoryPolarAxis
              key={i}
              dependentAxis
              domain={[0, 12]}
              style={{
                axisLabel: { fontSize: 32, padding: 10 },
                axis: { stroke: "none" },
                grid: { stroke: "grey", strokeWidth: 0.25, opacity: 1.0, strokeDasharray: 0 },
              }}
              labelPlacement="vertical"
              axisValue={i + 1}
              label={`${key} ${value}`}
              tickFormat={() => ""}
            ></VictoryPolarAxis>
          )
        })
      }
      <VictoryPolarAxis
        labelPlacement="parallel"
        tickFormat={() => ""}
        style={{
          axis: { stroke: "none" },
          grid: { stroke: "grey", opacity: 1.0, strokeDasharray: 0 }
        }}
      />
    </VictoryChart>
  )
}
