import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "dropdown" ]
  static classes = [ "closed", "opened", "enter", "leave" ]

  initialize() {
    this.isOpen = false
  }

  connect() {
    useClickOutside(this)
  }

  toggle() {
    this.isOpen = !this.isOpen
    this.reloadDropdown()
  }

  close(event) {
    event.preventDefault()
    this.isOpen = false
    this.reloadDropdown()
  }

  reloadDropdown() {
    if (this.isOpen) {
      this.removeClasses(this.leaveClass, this.dropdownTarget)
      this.addClasses(this.enterClass, this.dropdownTarget)
      this.removeClasses(this.closedClass, this.dropdownTarget)
      this.addClasses(this.openedClass, this.dropdownTarget)
    } else {
      this.removeClasses(this.enterClass, this.dropdownTarget)
      this.addClasses(this.leaveClass, this.dropdownTarget)
      this.removeClasses(this.openedClass, this.dropdownTarget)
      this.addClasses(this.closedClass, this.dropdownTarget)
    }
  }

  addClasses(classes, target) {
    classes.split(" ").forEach((value) => {
      target.classList.add(value)
    })
  }

  removeClasses(classes, target) {
    classes.split(" ").forEach((value) => {
      target.classList.remove(value)
    })
  }
}
